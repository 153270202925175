<template>
  <div :class="windowClass">
    <v-container>
      <template >
       <v-row  no-gutters>
         <v-col cols="3" class="pa-1">
            <va-stat-card 
               color="secondary"
               icon="mdi-cash-register"
               label="TODAY'S GROSS"
               :data="$money(TOTALGROSS)"
               sup="PHP"
             />
         </v-col>
 
          <v-col cols="3" class="pa-1">
            <va-stat-card 
               color="secondary"
               icon="mdi-account-cash-outline"
                label="TODAY'S PAYOUT"
               :data="$money(TOTALPAYOUT)"
               sup="PHP"
             />
         </v-col>
 
           <v-col cols="3" class="pa-1">
            <va-stat-card 
               color="secondary"
               icon="mdi-ticket-confirmation"
             label="TODAY'S TICKETS"
               :data="$count(tickets.item_count).toString()"
             />
         </v-col>
         <v-col cols="3" class="pa-1">
            <va-stat-card 
               color="secondary"
               icon="mdi-cellphone-iphone"
             label="TODAY'S ACTIVE POS"
               :data="$count(pos.item_count).toString()"
             />
         </v-col>
     </v-row>
      </template>
       <v-row class="pa-2">
           <v-col cols="12">
              <v-card dense>
         <v-toolbar>
             TRANSACTIONS
            <v-spacer/>
            As of : {{ this.$moment().format('MMM DD, YYYY')}}
         </v-toolbar>
         <v-card-text>
              <v-progress-linear
                 v-if="loading"
                 indeterminate
                 color="teal"
                 ></v-progress-linear>
             
             <table class="va-table def-font" id="over-table">
                 <tr>
                     <!-- <th>DRAW ID</th> -->
                     <th>GAME</th>
                     <!-- <th>STATUS</th> -->
                     <th>DRAW</th>
                     <th class="text-right">GROSS</th>
                     <th class="text-right">UNIT HITS</th>
                     <th class="text-right">AMOUNT HITS</th>
                 </tr>
                  <tr v-for="(item,index) in items" :key="index" >
                      <!-- <td class="border_bot">{{item.draw_id}} </td> -->
                     <td class="border_bot">{{item.game}} </td>
                     <!-- <td class="border_bot">{{item.status}} </td> -->
                     <td class="border_bot">{{item.draw_time}}</td>
                     <td class="text-right border_bot">{{$money(item.gross)}}</td>
                     <td class="text-right border_bot">{{$money(item.amount_of_winning)}}</td>
                     <td class="text-right border_bot">{{$money(item.payout)}}</td>
                 </tr>
                 <tr>
                     
                      <th  class="text-right" style="background: #efefef;" colspan="2">TOTAL:</th>
                     <th class="text-right"  style="background: #efefef;">{{$money(TOTALGROSS)}}</th>
                     <th class="text-right"  style="background: #efefef;">{{$money(TOTALHITS)}}</th>
                     <th class="text-right"  style="background: #efefef;">{{$money(TOTALPAYOUT)}}</th>
                 </tr>
                
             </table>
         </v-card-text>     
     </v-card>
         </v-col>
       
          <v-col cols="12">
              <v-card dense>
         <v-toolbar>
             BY COMMISSION
            <v-spacer/>
            As of : {{ this.$moment().format('MMM DD, YYYY')}}
         </v-toolbar>
         <v-card-text>
              <v-progress-linear
                 v-if="loading"
                 indeterminate
                 color="teal"
                 ></v-progress-linear>
             
             <table class="va-table def-font" id="over-table2">
                 <tr>
                     <th>DRAW</th>
                     <th>COMM(%)</th>
                     <th class="text-right">GROSS</th>
                      <th class="text-right">COMM</th>
                      <th class="text-right">NET</th>
                     <th class="text-right">HITS</th>
                     <th class="text-right">REVENUE</th>
                 </tr>
                  <tr v-for="(item,index) in COMMISSIONPERGROUP" :key="index" >
                     <td class="border_bot">{{item.draw_date}} </td>
                     <td class="border_bot">{{item.commission}}%</td>
                     <td class="text-right border_bot">{{$money(item.gross)}}</td>
                       <td class="text-right border_bot">{{$money(item.comm_amount)}}</td>
                        <td class="text-right border_bot">{{$money(item.net)}}</td>
                     <td class="text-right border_bot">{{$money(item.win_amount)}}</td>
                      <td class="text-right border_bot" :class="item.revenue>0?'text-success':'text-error'">{{$money(item.revenue)}}</td>
                 </tr>
                 <tr>
                     
                      <th  class="text-right" style="background: #efefef;"></th>
                     <th class="text-right"  style="background: #efefef;">TOTAL: </th>
                     <th class="text-right"  style="background: #efefef;">{{$money(COMTOTALGROSS)}}</th>
                     <th class="text-right"  style="background: #efefef;">{{$money(TOTALCOMMISSION)}}</th>
                     <th class="text-right"  style="background: #efefef;">{{$money(TOTALNET)}}</th>
                     <th class="text-right"  style="background: #efefef;">{{$money(COMTOTALHITS)}}</th>
                      <th class="text-right"  style="background: #efefef;" :class="TOTALREVENUE>0?'text-success':'text-error'">{{$money(TOTALREVENUE)}}</th>
                 </tr>
                
             </table>
         </v-card-text>     
     </v-card>
         </v-col>
 
       </v-row>
    </v-container>
     <v-overlay :value="overlay">
       <v-progress-circular indeterminate size="64"></v-progress-circular>
     </v-overlay>
   </div>
 </template>
 <script>
 import { API_MIXINS } from '@/mixins/api_mixins.js'
 import {
   mapMutations
 } from 'vuex'
   export default {
     mixins: [API_MIXINS],
     data: () => ({
         overlay: false,
           gross:'',
           commissiongroup:[],
           loading: false,
           tickets: {},
           pos:{},
           data_open_draws:[],
           items:[],
           inter_1: null,
     }),
     beforeCreate: function () {
       if (!this.$session.exists()) {
         this.$router.push('/')
       }
     },
     created() {
        if (this.$session.exists()) {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
           this.setLoggedIn(true) 
           this.getTransactions()
           this.inter_1 = setInterval(()=>{  this.getTransactions()}, 100000)
         } 
     },
     beforeDestroy(){
 
        if(this.inter_1 != null) { clearInterval(this.inter_1)}
        return true
     },
     computed:{ 
        windowClass() {
         if(this.$store.state.responsive){
           return "va-reponsive"
         } else {
           return "va-not-reponsive"
         }
       },
       TOTALGROSS() {
           var total = this.items.reduce((res,item)=>res += this.$IsNum(item.gross),0)
           return total
       },
         TOTALHITS() {
           var total = this.items.reduce((res,item)=>res += this.$IsNum(item.amount_of_winning),0)
           return total
       },
         TOTALPAYOUT() {
           var total = this.items.reduce((res,item)=>res += this.$IsNum(item.payout),0)
           return total
       },
       COMMISSIONPERGROUP(){
       
          var group = this.commissiongroup.reduce((res,item)=>{
            item.comm_amount =  this.calculate(item.commission, item.gross)
            item.net = this.$IsNum(item.gross) - item.comm_amount 
            item.revenue = this.$IsNum(item.gross) -  item.comm_amount  - this.$IsNum(item.win_amount)
             res.push(item)
             return res
          },[])
         return group
       },
        TOTALCOMMISSION() {
             var total = this.COMMISSIONPERGROUP.reduce((res,item)=>res += this.$IsNum(item.comm_amount),0)
             return total
         },
         TOTALNET() {
             var total = this.COMMISSIONPERGROUP.reduce((res,item)=>res += this.$IsNum(item.net),0)
             return total
         },
          TOTALREVENUE() {
             var total = this.COMMISSIONPERGROUP.reduce((res,item)=>res += this.$IsNum(item.revenue),0)
             return total
         },
        COMTOTALGROSS() {
             var total = this.commissiongroup.reduce((res,item)=>res += this.$IsNum(item.gross),0)
             return total
         },
          COMTOTALHITS() {
             var total = this.commissiongroup.reduce((res,item)=>res += this.$IsNum(item.win_amount),0)
             return total
         },
     },
     methods: {
       ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOpenDraws']),
       calculate(percent, gross){
         var comm = this.$IsNum(gross) * (this.$IsNum(percent)/100)
         return  comm
       },
       
       getTransactions(){
        //alert("call")
             this.loading = true
             this.items =[]
             var param = {}
             param.from_date = this.$moment().format('YYYY-MM-DD')
             param.to_date =  param.from_date
             param.Method = "perdraw"
              this.$http.post("report/transactions",param).then(response => {
                 response.data.transactions !=null?this.items = response.data.transactions:this.items =[]
                this.getPerCom()
                this.getStatCount()
           }).catch(e => {
             console.log(e.data)
           })
         }, 
          getPerCom(){
             this.loading = true
             this.commissiongroup =[]
             var param = {}
             param.from_date =  this.$moment().format('YYYY-MM-DD')
             param.to_date =  this.$moment().format('YYYY-MM-DD')
             param.Method = "per_commission"
              this.$http.post("report/transactions",param).then(response => {
                 response.data.items !=null?this.commissiongroup = response.data.items:this.commissiongroup =[]
                 this.loading = false
           }).catch(e => {
             console.log(e.data)
           })
         }, 
          getStatCount(){
             var param = {}
             param.from_date =  this.$moment().format('YYYY-MM-DD')
             param.to_date =   this.$moment().format('YYYY-MM-DD')
             param.Method = "stat_count"
              this.$http.post("report/transactions",param).then(response => {
                 response.data.ticket !=null?this.tickets = response.data.ticket:this.tickets ={}
                 response.data.pos !=null?this.pos = response.data.pos:this.pos ={}
           }).catch(e => {
             console.log(e.data)
           })
         },   
     }
   }
 </script>